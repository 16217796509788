import createEmotionCache from '@/createEmotionCache'
import { LightboxImageViewEventHandler } from '@/custom-events'
import StoreProvider from '@/store/StoreProvider'
import theme from '@/theme'
import '@code-hike/mdx/dist/index.css'
import { CacheProvider, EmotionCache } from '@emotion/react'
import CssBaseline from '@mui/material/CssBaseline'
import { ThemeProvider } from '@mui/material/styles'
import { AppProps } from 'next/app'
import Head from 'next/head'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import LoginProfileLoader from '@/auth/LoginProfileLoader'
import { ScrollProvider } from '@/layouts/MainLayout/components/Sidebar/components/ScrollProvider'

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache()

interface MyAppProps extends AppProps {
    emotionCache?: EmotionCache
}

export default function MyApp(props: MyAppProps) {
    const { Component, emotionCache = clientSideEmotionCache, pageProps } = props
    return (
        <CacheProvider value={emotionCache}>
            <Head>
                <meta name="viewport" content="initial-scale=1, width=device-width" />
            </Head>
            <ScrollProvider>
                <StoreProvider rootStoreInitialState={undefined}>
                    <ThemeProvider theme={theme}>
                        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
                        <CssBaseline />
                        <Component {...pageProps} />
                        <LoginProfileLoader />
                    </ThemeProvider>
                </StoreProvider>
                <ToastContainer position="top-center" hideProgressBar autoClose={2000} pauseOnHover draggable />
                <LightboxImageViewEventHandler />
            </ScrollProvider>
        </CacheProvider>
    )
}
