import { ApiHelper, ApiHelperWithData, ApiResponseWithData, BaseRequest, splitParams } from '..'
import { PagerData, Pds, PdsSimple, UploadedFile } from '../model'

/**
 * 자료실(PDS) API
 */
export class PdsApi {
    private withData: ApiHelperWithData
    constructor(private helper: ApiHelper) {
        this.withData = new ApiHelperWithData(helper)
    }

    /**
     * Pds 게시물 목록 조회 - 페이징
     */
    list = (
        params: {
            title?: string
            rowsPerPage: number
            pageNumber: number
        } & BaseRequest,
    ): Promise<ApiResponseWithData<{ pagerData: PagerData<PdsSimple> }>> => {
        const url = '/p/api/pds/doc/list'
        return this.withData.postJson(url, ...splitParams(params))
    }

    /**
     * Pds 게시물 정보 조회
     */
    info = (
        params: {
            pdsId: number
        } & BaseRequest,
    ): Promise<ApiResponseWithData<{ pds: Pds }>> => {
        const [data, extra] = splitParams(params)
        const url = `/api/pds/info/${data.pdsId}`
        return this.withData.post(url, undefined, extra)
    }
}
