import { Black_Han_Sans, Fira_Code, Nanum_Gothic_Coding, Noto_Sans_KR } from '@next/font/google'

export const notoSansKR = Noto_Sans_KR({
    weight: ['300', '400', '500', '700', '900'],
    subsets: ['latin'],
    display: 'swap',
    fallback: ['Helvetica', 'Arial', 'sans-serif'],
})

export const nanumGothicCoding = Nanum_Gothic_Coding({
    weight: ['400', '700'],
    subsets: ['latin'],
    display: 'swap',
    fallback: ['monospace'],
})

export const blackHanSans = Black_Han_Sans({
    weight: ['400'],
    subsets: ['latin'],
    display: 'swap',
    fallback: ['Helvetica', 'Arial', 'sans-serif'],
})

export const firaCode = Fira_Code({
    weight: ['300', '400', '500', '600', '700'],
    subsets: ['cyrillic', 'cyrillic-ext', 'greek', 'greek-ext', 'latin', 'latin-ext'],
    display: 'swap',
    fallback: ['monospace'],
})
