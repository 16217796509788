import { ApiHelper, ApiHelperWithData, ApiResponseWithData, BaseRequest, splitParams } from '..'
import { UserProfile } from '../model/UserProfile'

export class ProfileApi {
    private withData: ApiHelperWithData
    constructor(private helper: ApiHelper) {
        this.withData = new ApiHelperWithData(helper)
    }

    /**
     * 로그인한 사용자의 사용자 프로필 조회
     */
    profileMe = (params: BaseRequest): Promise<ApiResponseWithData<{ profile: UserProfile }>> => {
        const url = '/api/auth/profile-me'
        return this.withData.post(url, ...splitParams(params))
    }
}
