import { createTheme } from '@mui/material/styles'
import overrides from './overrides'
import palette from './palette'
import typography from './typography'
export * from './fonts'

// Create a theme instance.
const theme = createTheme({
    palette,
    typography,
    components: {
        ...overrides,
    },
})

export default theme
