import React, { createContext, useContext, useState } from 'react'

interface ScrollContextProps {
    scrollPosition: number
    setScrollPosition: React.Dispatch<React.SetStateAction<number>>
}

const ScrollContext = createContext<ScrollContextProps | undefined>(undefined)

type Props = {
    children: React.ReactNode
}

export const ScrollProvider = (props: Props) => {
    const [scrollPosition, setScrollPosition] = useState(0)

    return (
        <ScrollContext.Provider value={{ scrollPosition, setScrollPosition }}>{props.children}</ScrollContext.Provider>
    )
}

export const useScroll = (): ScrollContextProps => {
    const context = useContext(ScrollContext)

    if (!context) {
        throw new Error('useScroll must be used within a ScrollProvider')
    }

    return context
}
