import { red } from '@mui/material/colors'
import { PaletteOptions } from '@mui/material/styles'

const palette: PaletteOptions = {
    primary: {
        contrastText: '#fff',
        light: '#6f78ff',
        main: '#4859EE',
        dark: '#0025a7',
    },
    secondary: {
        contrastText: '#FFF',
        light: '#ff6333',
        main: '#ff3d00',
        dark: '#b22a00',
    },
    error: {
        main: red.A400,
    },
    text: {
        primary: '#161616',
        secondary: '#616161',
        disabled: '#969696',
    },
}
export default palette
