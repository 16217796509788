import { errorToMessage } from './errors/error-messages'
import log from './log'
import { AuthApi } from './user-apis/AuthApi'

import { ProfileApi } from './user-apis/ProfileApi'
import { UserApi } from './user-apis/UserApi'
import { PdsApi } from './user-apis/PdsApi'
import { ApiHelper } from './helper'
import { ErrorResponseInterceptor, ResponseInterceptor } from './base'

export class UserMainApi {
    helper: ApiHelper
    auth: AuthApi
    user: UserApi
    profile: ProfileApi
    pds: PdsApi

    constructor(
        public apiBaseURL: string,
        createApiHeader: () => Record<string, string>,
        responseInterceptor: ResponseInterceptor,
        errorResponseInterceptor: ErrorResponseInterceptor,
        debug = false,
    ) {
        log.debug('create Api for ', apiBaseURL)

        // create helper
        const helper = new ApiHelper(
            apiBaseURL,
            createApiHeader,

            responseInterceptor,
            errorResponseInterceptor,
            errorToMessage,
            debug,
        )

        this.helper = helper

        this.auth = new AuthApi(helper)
        this.user = new UserApi(helper)
        this.profile = new ProfileApi(helper)
        this.pds = new PdsApi(helper)
    }
}
